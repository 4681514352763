import React, { memo } from 'react'
import { Container } from '~ux'

const NotFoundPage = () => {
  return (
    <section className="bg-[#F9F9F8]">
      <Container>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Container>
    </section>
  )
}

export default memo(NotFoundPage)
